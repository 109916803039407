import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection,updateDoc, addDoc, query, where, getDocs, Timestamp } from 'firebase/firestore'; // Firestore methods
import { auth, db } from '../../firebase/Firebase'; // Firebase setup
import './Ship_your_Item.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Select from "react-select";

const countryOptions = [
  { value: 'Maldives', code: 'mv', label: <><img src="https://flagcdn.com/w20/mv.png" alt="Maldives" /> Maldives</> },

];


const ShipYourItem = ({ onClose, userId, selectedRequestIds }) => {
  const [step, setStep] = useState(1);
  const [packageName, setPackageName] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [totalBillableWeight, setTotalBillableWeight] = useState(0); // State to store total billable weight
  const [orderNumbers, setOrderNumbers] = useState([]); // Store order numbers for saving
  const [warehouseLocation, setWarehouseLocation] = useState(''); // Store warehouse location (country)
  const [rateUSD, setRateUSD] = useState(null); // State to store the fetched rate in USD
  const [rateMVR, setRateMVR] = useState(0); // State to store the rate in MVR
  const [rateMYR, setRateMYR] = useState(0); // State to store the rate in MYR
  const [sheetRate, setSheetRate] = useState(null); // Store sheet rate
  const [showPopup, setShowPopup] = useState(false);
  const API_KEY = 'AIzaSyCdDHnrqB4oh00AZLPFT9Cu2iezsAqhB-o'; // Replace with your Google Sheets API key
  const SHEET_ID = '1Vh7hJnViAnV4B5jhXGyLoq23XKSGiI0sh4SFwntaoxA'; // Replace with your Google Sheet ID
  const SHEET_RANGE = 'Sheet1'; // Sheet range
  const [isAgreed, setIsAgreed] = useState(false);
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  const [newAddressData, setNewAddressData] = useState({
    receiverName: '',
    contactNumber: '',
    country: '',
    address: '',
    postCode: '',
  });


  const [userData, setUserData] = useState({
    userName: '',
    userId: '',
    shippingAddresses: [],
  });

  const [isLoading, setIsLoading] = useState(false); //Manage the loader state
  

  // Function to round up the weight according to the defined weight bands
  const roundUpWeight = (weight) => {
    const weightBands = [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
      10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18,
      18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
    ];

    // Use find to get the first weightBand greater than or equal to the weight
    return weightBands.find((band) => weight <= band) || weightBands[weightBands.length - 1];
  };

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddressData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCountryChange = (selectedOption) => {
    setNewAddressData((prevData) => ({
      ...prevData,
      country: selectedOption.value,
      countryFlagCode: selectedOption.code, // Store the selected country's flag code
    }));
  };

  const handleNewAddressSubmit = async (e) => {
    e.preventDefault();

    const { receiverName, contactNumber, country, countryFlagCode, address, postCode } = newAddressData;

    // Validation to ensure all fields are filled
    if (!receiverName || !contactNumber || !country || !countryFlagCode || !address || !postCode) {
      toast.error("Please fill in all the fields before proceeding.");
      return;
    }



    setAddressLoading(true); // Start loader
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const newAddress = {
          receiverName,
          contactNumber,
          country,
          countryFlagCode, // Save the flag code
          address,
          postCode,
        };

        // Fetch current addresses
        const userSnapshot = await getDoc(userDocRef);
        let currentAddresses = userSnapshot.data()?.shippingAddresses || [];

        // Append the new address
        currentAddresses = [...currentAddresses, newAddress];

        // Update Firestore
        await updateDoc(userDocRef, { shippingAddresses: currentAddresses });

        // Update local state
        setUserData((prevData) => ({
          ...prevData,
          shippingAddresses: currentAddresses,
        }));

        // Reset form
        setNewAddressData({
          receiverName: '',
          contactNumber: '',
          country: '',
          countryFlagCode: '',
          address: '',
          postCode: '',
        });

        // Close address form
        setShowAddAddressForm(false);
        toast.success('Address submitted successfully!');
      }
    } catch (error) {
      console.error('Error adding address:', error);
      toast.error('Failed to save address. Please try again.');
    } finally {
      setAddressLoading(false); // Stop loader
    }
  };



  // Function to fetch user data including shipping addresses
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log("Request Id: ", selectedRequestIds);
        const userDocRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserData({
            userName: userData['User Name'] || '',
            userId: userData['User_ID'] || '',
            shippingAddresses: userData.shippingAddresses || [],
          });

          handleRequestToShip();
        } else {
          console.log('No such document!');
        }
      } else {
        // Redirect to sign-in if not authenticated
        window.location.href = '/signin';
      }
    };

    fetchUserData();
  }, []);

  // Function to handle "Request to Ship Your Items" button click
  const handleRequestToShip = async () => {
    if (selectedRequestIds.length === 0) {
      alert("Please select at least one item to ship.");
      return;
    }

    try {
      const shippingCollectionRef = collection(db, "Shipping_items");
      const createdAt = Timestamp.now();

      let totalBillableWeight = 0;
      let orderNumbers = [];
      let warehouseLocation = '';

      // Fetch the details of the selected items from Firestore
      const selectedItems = await Promise.all(
        selectedRequestIds.map(async (requestId) => {
          const q = query(
            collection(db, "Received_packages"),
            where("Order_ID", "==", requestId)
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.billable_weight) {
                totalBillableWeight += data.billable_weight; // Add billable weight
                orderNumbers.push(data.Order_ID); // Collect order numbers
                warehouseLocation = data.Warehouse_Location || ''; // Get warehouse location (country)
              } else {
                console.warn(`No billable_weight found for request ID: ${requestId}`);
              }
            });
          } else {
            console.error("No document found with Order_ID:", requestId);
          }
        })
      );


      setTotalBillableWeight(totalBillableWeight);
      setWarehouseLocation(warehouseLocation);

      // Fetch rates based on the total billable weight and warehouse location (country)
      await calculatePrice(totalBillableWeight, warehouseLocation);
      
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const calculatePrice = async (totalWeight, warehouseLocation) => {
    let finalWeight = roundUpWeight(totalWeight);

    // If the weight exceeds 70, fetch the special rate for weights above 70
    if (totalWeight > 70) {
      await fetchGoogleSheetRateAbove70(totalWeight, warehouseLocation);
    } else {
      await fetchGoogleSheetRate(finalWeight, warehouseLocation);
    }
  };

  const fetchGoogleSheetRateAbove70 = async (actualWeight, warehouseLocation) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;

      // Find the row for 70+ rate
      const matchingRow = rows.find(
          (row) => row[1] === '70 +' && row[3] === warehouseLocation && row[0] === 'Non- Document'
      );

      if (matchingRow) {
        const ratePerKgUSD = parseFloat(matchingRow[2]); // Get the rate for 70+
        const additionalFee = selectedRequestIds.length > 1 ? 1 : 0; // Add $1 if multiple request IDs
        const calculatedPriceUSD = (ratePerKgUSD * actualWeight + additionalFee) * 1.32; // Apply 32% markup
        setSheetRate(calculatedPriceUSD.toFixed(2));
        setRateMVR((calculatedPriceUSD * 15.4).toFixed(2)); // MVR conversion
        setRateMYR((calculatedPriceUSD * 4.2).toFixed(2)); // MYR conversion
      } else {
        setSheetRate('Rate not available for this weight and type.');
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };

  const fetchGoogleSheetRate = async (roundedWeight, warehouseLocation) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;

      // Find the rate matching the rounded weight and warehouse location
      const matchingRow = rows.find(
          (row) => parseFloat(row[1]) === roundedWeight && row[3] === warehouseLocation && row[0] === 'Non- Document'
      );

      if (matchingRow) {
        const ratePerKgUSD = parseFloat(matchingRow[2]);
        const additionalFee = selectedRequestIds.length > 1 ? 1 : 0; // Add $1 if multiple request IDs
        const calculatedPriceUSD = (ratePerKgUSD + additionalFee) * 1.32; // Apply 32% markup
        const calculatedPriceMVR = calculatedPriceUSD * 15.4; // MVR conversion
        const calculatedPriceAED = calculatedPriceUSD * 4.2; // MYR conversion

        setSheetRate(calculatedPriceUSD.toFixed(2));
        setRateMVR(calculatedPriceMVR.toFixed(2));
        setRateMYR(calculatedPriceAED.toFixed(2));
      } else {
        setSheetRate('Rate not available for this weight and type.');
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };




  const handleClickSubmit = async () => {
    // Assuming 'isAgreed' is the state for the checkbox value
    if (!isAgreed) {
      toast.error('Please agree to the terms and conditions to proceed!');
      return; // Stop the submission if the checkbox is not selected
    }
  
    setIsLoading(true); // Show loader before starting the async operation
  
    try {
      const shippingCollectionRef = collection(db, "Shipping_items");
      const createdAt = Timestamp.now();
  
      // Add a new document to "Shipping_items" collection with the selected Request IDs
      await addDoc(shippingCollectionRef, {
        request_ids: selectedRequestIds,
        total_billable_weight: totalBillableWeight, // Save total billable weight
        warehouse_location: warehouseLocation, // Save warehouse location (country)
        Customer_ID: userData.userId,
        packageName:packageName,
        usdRate: sheetRate,
        mvrRate: rateMVR,
        receiverAddress:receiverAddress,
        createdAt,
      });
  
      // Update the status to "Ready to Ship" in each related document in "Received_packages"
      const receivedPackagesCollectionRef = collection(db, "Received_packages");
  
      // Loop through each request ID and update the corresponding document
      for (const requestId of selectedRequestIds) {
        const q = query(receivedPackagesCollectionRef, where("Order_ID", "==", requestId));
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach(async (docSnapshot) => {
          const docRef = doc(db, "Received_packages", docSnapshot.id);
  
          // Update the status to "Ready to Ship"
          await updateDoc(docRef, {
            Status: "Ready to Ship",
          });
        });
      }
      

      setIsLoading(false); // Hide loader when operation completes

      // Show success toast notification
      toast.success('Request submitted and updated to "ready to ship"!', {
        
      });

      onClose(true); // Close the modal after successful submission
    } catch (error) {
      setIsLoading(false); // Hide loader on error

      console.error("Error submitting the request:", error);

      // Show error toast notification
      toast.error("There was an issue submitting your request. Please try again.", {
       
      });
    }
  };
  

  
  // Calculate progress percentage based on the current step
  const progress = (step / 3) * 100;

  const handleNext = () => {
    if (step === 1 && packageName.trim() === '') {
      toast.error('Please provide a name for your package before proceeding.');
      return;
    }
    if (step === 2 && !receiverAddress) {
      toast.error('Please select a receiver address before proceeding');
      return; // Prevent moving to the next step
    }

    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  console.log(userData.shippingAddresses);
  return (
  <div className='popup-overlay'>
    {/* Loader component */}
    {isLoading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
    
    <div className="popup-container">
   
      <div className="popup-content">
      <button className="closeSteps-btn" onClick={() => onClose(false)}>×</button>
      <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
        </div>

        {step === 1 && (
          <div>  
            <h3 className='popupSteps1'>Step 1 of 3</h3>
            <label className='popupLabel1'>Provide a name for your package</label>
            <input
              style={{marginBottom:'20px'}}
              type="text"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              placeholder="Type a name that is easy to track"
            />
            <div className='.btnStep1-container'>
              <center>
            <button className='btnNext' onClick={handleNext}>Next</button>
            </center>
            </div>
          </div>
        )}

        {step === 2 && (
            <div>
              <h3 className='popupSteps2'>Step 2 of 3</h3>
              <label>Receiver Address</label>

              {userData.shippingAddresses.length > 0 ? (
                  userData.shippingAddresses.map((address, index) => (
                      <div key={index} className="radio-address">
                        <label>
                          <input
                              type="radio"
                              value={address.address}
                              checked={receiverAddress === address.address}
                              onChange={(e) => setReceiverAddress(e.target.value)}
                          />
                          <span>{address.address}, {address.country}, {address.postCode}</span>
                        </label>
                      </div>
                  ))
              ) : (
                  <p>No saved addresses found.</p>
              )}

              {/* Add New Address Button */}
              <div className="add-new-address-container">
                <button className="btn bg-emerald-700 text-amber-50 p-3 rounded-2xl hover:bg-emerald-500" onClick={() => setShowAddAddressForm(true)}>
                  + Add New Address
                </button>
              </div>

              {/* Add New Address Form */}
              {showAddAddressForm && (
                  <div className="add-address-form">
                    <h4>Add New Address</h4>
                    <div className="input-group-address">
                      <label>Receiver's Full Name</label>
                      <input
                          type="text"
                          name="receiverName"
                          value={newAddressData.receiverName}
                          onChange={handleNewAddressChange}
                          placeholder="Enter receiver's name"
                          required
                      />
                    </div>
                    <div className="input-group-address">
                      <label>Contact Number</label>
                      <input
                          type="text"
                          name="contactNumber"
                          value={newAddressData.contactNumber}
                          onChange={handleNewAddressChange}
                          placeholder="Enter contact number"
                          required
                      />
                    </div>
                    <div className="input-group-address">
                      <label>Country</label>
                      <Select

                          options={countryOptions}
                          onChange={handleCountryChange}
                          value={countryOptions.find(
                              (option) => option.value === newAddressData.country
                          )}
                          placeholder="Select country ..."
                          isSearchable={true}
                          className="country-select"
                      />
                    </div>
                    <div className="input-group-address">
                      <label>Address</label>
                      <input
                          type="text"
                          name="address"
                          value={newAddressData.address}
                          onChange={handleNewAddressChange}
                          placeholder="Enter address"
                          required
                      />
                    </div>
                    <div className="input-group-address">
                      <label>Postal Code</label>
                      <input
                          type="text"
                          name="postCode"
                          value={newAddressData.postCode}
                          onChange={handleNewAddressChange}
                          placeholder="Enter postal code"
                          required
                      />
                    </div>
                    <div className="flex justify-space-between w-full">
                      <button
                          className="btn bg-blue-700 hover:bg-blue-500 p-2 rounded-2xl text-amber-50"
                          onClick={handleNewAddressSubmit} // Directly call the handler
                      >
                        Add Address
                      </button>
                      <button
                          type="button"
                          className="btn bg-red-700 hover:bg-red-500 p-2 rounded-2xl text-amber-50"
                          onClick={() => setShowAddAddressForm(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
              )}


              <div className='btnStep2-container'>
                <button className='btnBack' onClick={handleBack}>Back</button>
                <button className='btnNext' onClick={handleNext} disabled={!receiverAddress}>
                  Next
                </button>
              </div>
            </div>
        )}


        {step === 3 && (
          <div>
            <h3 className='popupSteps3'>Step 3 of 3</h3>
          <div className='packageInfo'>
            <p className='packInfo'><strong>Package Name:</strong> {packageName}</p>
            <p className='packInfo'><strong>Shipping Method:</strong> {shippingMethod}</p>
            <p className='packInfo'><strong>Receiver Address:</strong> {receiverAddress}</p>
            <p className='packInfo'><strong>Total Billable Weight:</strong> {totalBillableWeight} kg</p>
            

            <div className="rate-display">
              <p className='rateDis'><strong>Rate (USD):</strong> {(sheetRate * 1.32).toFixed(2)}</p>
              <p className='rateDis'><strong>Rate (MVR):</strong> {(rateMVR * 1.32).toFixed(2)}</p>
              <p className='rateDis'><strong>Rate (MYR):</strong> {rateMYR}</p>
            </div>
          </div>

          <div className="checkbox-flex">
                <input
                  type="checkbox"
                  className="termsCheck2"
                  id="terms"
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)} // Update state on checkbox change
                />
                <label htmlFor="terms" className="termsLabel2">I agree with the terms and conditions</label>
          </div>
          <div className='btnStep3-container'>
            <button className='btnBack' onClick={handleBack}>Back</button>
            <button
              className='btnSubmit'
              onClick={handleClickSubmit}
              
            >
              Submit
            </button>
          </div>

          
          </div>
        )}
      </div>
    </div>
    <ToastContainer/>
    </div>
   
  );
};

export default ShipYourItem;
